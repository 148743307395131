/* Home.css */
.home {
  color: white;
  height: 100vh;
  display: flex;
  overflow: hidden;
  position: relative;
}

.home h1 {
  overflow: hidden;
  font-size: 90px;
  line-height: 74px;
  margin: 0;
  float: left;
  padding: 10px;
  position: relative;
  z-index: 2;

}

.home h1::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  z-index: -1;
}

.home p {
  font-size: 30px;
  letter-spacing: 5px;
  padding-left: 15px;
  margin: 0;
  margin-top: 10px;
  float: left;
  order: 3;
}

.left-column {
  width: 50%;
  display: flex;
  float: left;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 4% 0;
  z-index: 1;
}

.right-column {
  width: 50%;
  display: flex;
  float: left;
  justify-content: center;
  align-items: center;
  z-index: 1;
}


/* Media query for medium devices (e.g., tablets) */
@media screen and (max-width: 992px) {
  .home {
    height: 100vh;
    position: relative;
  }

  .home p {
    font-size: 20px;
    width: 100%;
    padding-left: 0px;
    letter-spacing: 2px;
    margin-bottom: 15px;
  }

  .left-column {
    width: auto;
    background-color: rgba(128, 128, 128, 0.164);
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2;
  }

  .right-column {
    width: 100%;
    height: 100%;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .right-column div {
    height: 100%;
  }
}

/* Media query for small devices (e.g., phones) */
@media screen and (max-width: 576px) {
  .home h1 {
    font-size: 70px;
  }

  .home p {
    font-size: 16px;
  }
}