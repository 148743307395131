/* Header.css */
.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.656); 
  z-index: 999;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.nav-links li {
  margin: 0 30px;
}

.nav-links a {
  text-decoration: none;
  color: white; 
  font-weight: bolder; 
  font-size: 115%;
  transition: color 0.3s ease-in-out;
}

.nav-links a:hover{
cursor: pointer;
color: #fed52e;
}

.nav-links a.active {
  color: #fed52e;
}


