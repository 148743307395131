/* About.css */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

@keyframes colorChange {
  0% {
    color: #be7b00;
  }

  33.33% {
    color: red;
  }

  66.66% {
    color: #ffd900;
  }

  100% {
    color: orange;
  }
}

@keyframes waveMotion {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-2px);
  }
}



.about {
  /* height: 100vh; */
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  padding: 0 50px;
  margin: 40px 0;
}

.about-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
}

.about-left-column {
  padding-top: 145px;
  flex: 1;
  margin-right: 20px;
  text-align: center;
}

.about-left-column img {
  width: 300px;
  border-top-left-radius: 80px 80px;
  border-bottom-right-radius: 80px 80px;
}

.about-right-column {
  padding: 20px;
  flex: 2;
}

.welcome {
  text-align: center;
  font-size: 300%;
}

.welcome h1 {
  margin-bottom: 30px;
}

.about-story p {
  text-align: justify;
  margin: 0;
  font-size: 24px;
  line-height: 1.5;
}

.connection-links {
  display: flex;
  justify-content: center;
  gap: 45px;
  margin-top: 20px;
}


.connection-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #474545;
  color: white;
  padding: 10px;
  font-size: 30px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

}

.connection-link:hover {
  cursor: pointer;
  background-color: #686868;
  color: #fed52e;
}


/* Animation keyframes for twinkling effect */
@keyframes twinkle {
  from {
    opacity: .5;
    transform: translateY(0) scale(.7);
  }

  to {
    opacity: 1;
    transform: translateY(20px) scale(1);
  }
}


.stars-container {
  position: absolute;
  width: 70%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.stars {
  position: absolute;
  width: 70%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}

.star1 {
  top: 120px;
  left: 70px;
  font-size: medium;
}

.star2 {
  top: 100px;
  left: 50px;
  font-size: small;
}

.star3 {
  top: 140px;
  left: 50px;
  font-size: larger;
}

.stars::before {
  content: '✦';
  position: absolute;
  color: #fff;
  animation: twinkle 2s linear infinite alternate;
}

/* Apply the wave animation to each letter */
.fire-text {
  display: inline-block;
  position: relative;
  font-size: 24px;
  /* Adjust the font size as needed */
}

/* Apply the animation delay to each letter */
.fire-text span {
  display: inline-block;
  animation: colorChange 1s infinite linear, waveMotion 1s infinite ease-in-out;
  animation-delay: calc(0.1s * var(--index));
  /* Adjust the delay timing */
}


@media screen and (min-width: 1250px) and (max-width: 1550px) {
  .about-left-column img {
    width: 250px;
  }

  .connection-link {
    width: 35px;
    height: 35px;
  }

  .welcome {
    font-size: 250%;
  }

  .about-story p {
    font-size: 20px;
  }

  .star1 {
    top: 120px;
    left: 40px;
    font-size: medium;
  }

  .star2 {
    top: 100px;
    left: 20px;
    font-size: small;
  }

  .star3 {
    top: 140px;
    left: 20px;
    font-size: larger;
  }
}

@media screen and (min-width: 992px) and (max-width: 1250px) {
  .about-left-column img {
    width: 220px;
  }

  .connection-links {
    gap: 35px;
  }

  .connection-link {
    width: 30px;
    height: 30px;
  }

  .welcome {
    font-size: 210%;
  }

  .about-story p,
  .fire-text {
    font-size: 20px;
  }

  .star1 {
    top: 100px;
    left: 30px;
  }

  .star2 {
    top: 80px;
    left: 10px;
    font-size: small;
  }

  .star3 {
    top: 120px;
    left: 10px;
  }
}


/* Media query for medium devices (e.g., tablets) */
@media screen and (max-width: 992px) {
  .about-content {
    flex-direction: column;
  }

  .about-left-column,
  .about-right-column {
    width: 100%;
    text-align: center;
    padding: 0;
    margin-bottom: 20px;
  }

  .connection-links {
    gap: 20px;
    margin-top: 20px;
  }

  .connection-link {
    width: 50px;
    height: 50px;
  }

  .about-left-column img {
    margin-top: 80px;
    width: 40%;
    margin-bottom: 20px;
    border-top-left-radius: 50px 50px;
    border-bottom-right-radius: 50px 50px;
  }

  .welcome {
    font-size: 180%;
  }

  .about-story p,
  .fire-text {
    font-size: 20px;
    margin-bottom: 5px;
  }
}

/* Media query for small devices (e.g., phones) */
@media screen and (max-width: 576px) {

  .connection-links {
    margin-top: 20px;
  }

  .connection-link {
    width: 35px;
    height: 35px;
  }

  .about-left-column img {
    margin-top: 60px;
    width: 140px;
    margin-bottom: 10px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .welcome {
    font-size: 120%;
  }

  .about-story p,
  .fire-text {
    font-size: 14px;
    margin-bottom: 5px;
    text-align: justify;
  }

  .star1 {
    top: 60px;
    left: 15px;
  }

  .star2 {
    top: 40px;
    left: 0px;
    font-size: small;
  }

  .star3 {
    top: 80px;
    left: 0px;
  }
}

.demo-link {
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.demo-link:hover {
  cursor: pointer;
  color: #fed52e;
}