/* Pro */
/* ---------------- Animations ---------------- */
@keyframes BackgroundAnimation {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 0%;
  }
}


/* ---------------- Project Component General ---------------- */
.projects {
  background: linear-gradient(120deg, #4f60e23c, #12011a, #fc484849);
  background-size: 200% 200%;
  animation: BackgroundAnimation 20s ease infinite;
}

.projects-container {
  display: flex;
  height: 90vh;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 4% 2%;
}

.fade-enter {
  opacity: 0.4;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* ____________________________________________________________________________________ */
/* Media screen changes for ALL project/project list/project details */


/* Media query for medium devices (e.g., tablets) */
@media screen and (min-width: 840px) and (max-width: 1282px) {

  /* CSS rules specific to medium devices */
  .projects-container {
    flex-direction: column;
    height: auto;
  }

  .project-details {
    order: 1;
    text-align: left;
    /* Place project details above the list */
  }

  .projects-list-column {
    width: 100%;
  }

  .projects-container h1 {
    margin-top: 30px;
    font-size: 75px;
  }

  .project-list li {
    font-size: 20px;
  }


  .project-details-container h2 {
    font-size: 250%;
  }
}

/* Media query for small devices (e.g., phones) */
@media screen and (max-width: 840px) {
  .projects-container {
    flex-direction: column;
    height: auto;
  }

  .projects-list-column {
    width: 100%;
  }

  .projects-container h1 {
    margin-top: 30px;
    font-size: 75px;
  }

  .project-list li {
    font-size: 20px;
  }

  .project-details-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .project-details-left-column,
  .project-details-right-column {
    width: 100%;
  }

  .project-details-right-column h2 {
    text-align: center;
  }

  .code-used {
    margin-top: 10em;
    font-size: 12px;
    flex-wrap: wrap;
  }

  .code-tag {
    margin: 6px;
  }

  .project-details-container img {
    top: 0;
    width: 25em;
    margin-top: auto;
    padding: 1em;
  }

  .project-details-container p,
  .project-details-container .project-links {
    text-align: center;
    font-size: 16px;
  }
}

@media screen and (max-width: 650px) {
  .project-details-container h2 {
    font-size: 160%;
  }

  .project-details-container p,
  .project-details-container .project-links {
    font-size: 14px;
  }

  .proj-details-img img {
    width: 80%;
  }

  .code-used {
    gap: 5px;
  }
}