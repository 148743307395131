/* ProjectDetails.css */
/* ---------------- Project Details Container ---------------- */
@keyframes rollout {
  0% { transform: translateX(100px); }
  100% { transform: none; }
}

.project-details-container {
  align-items: center;
  justify-content: center; 
  display: flex;
  width: 85%;
  margin: auto;
  padding: 20px;
  background-color: #38275329;
  border-top-right-radius: 80px;
  border-bottom-left-radius: 80px;
  border: solid 1.5px #3b374023;
  gap: 20px;
  box-shadow: 0 0 50px #ffffff17;
  transition: background-color 0.8s ease-in-out, box-shadow 0.8s ease-in-out;
}


.project-details {
  align-items: flex-start;
  justify-content: center;
  padding: 1em;
  margin: auto;
  animation: rollout 0.5s ease-in-out;
}

.project-details-container:hover {
  background-color: #3b2e5027;
  box-shadow: 0 0 50px #ffffff2f;
}

/* ---------------- Project Details Left Side ---------------- */
.project-details-left-column {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-content: center; */
  /* align-content: flex-end; */
  /* flex-wrap: wrap; */
  height: 19em;
}

/* .proj-details-img { */
  /* position: absolute; */
  /* justify-content: center; */
  /* width: 100%; */
/* } */

.proj-details-img img {
  position: absolute;
  width: 90%;
  transform: translateX(-50%); 
  left: 50%; 
  opacity: 0;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
  margin-top: 2em;
  z-index: 1;
  transition: opacity 2s ease-in-out;
}

.proj-details-img img.active {
  opacity: 1;
  z-index: 2;
}

.code-used {
  display: flex;
  align-items: center;
  justify-content: center; 
  width: 100%;
  padding: 4px;
  margin: 1em 0;
  gap: 10px;
}

.code-tag {
  width: auto;
  text-align: center;
  background-color: #3d226862;
  border: solid 1.5px #615a6963;
  border-radius: 20px;
  color: white;
  padding: 5px 10px;
}

.code-tag:hover  {
  color: #2ed8fe;
  transition: color .3s ease-in-out;
  cursor: default;
}

/* ---------------- Project Details Right Side ---------------- */

.project-details-right-column {
  flex: 1;
  width: 50%;
}

.project-details-container:hover h2 {
  color: #fed52e;
  transition: color 1s ease-in-out;
}

.project-details-container p,
h2 {
  text-align: left;
  padding-right: 20px;
  padding-left: 20px;
}

.project-details-container h2 {
  font-size: 250%;
  margin: 0;
}


.project-details-container p {
  font-size: 120%;
}


.project-links {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  padding: 10px;
  gap: 15px;
}

.project-link {
  width: 40%;
  background-color: #3d226862;
  border: solid 1.5px #615a6963;
  border-radius: 3px;
  color: white;
  padding: 4px;
  text-decoration: none;
}

.project-link:hover {
  background-color: #4745456e;
  color: #fed52e;
  transition: color 0.3s ease-in-out;
  border: solid 1.5px #68646c63;
  cursor: pointer;
}


